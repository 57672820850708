<template>
	<div class="container">
		<div>
			<h1 class="header_text">Will you be my valentine?</h1>
		</div>
		<div class="gif_container">
			<img src="@/assets/main_photo.jpg" alt="askValentinePhoto" width="300px" />
			<img
				style="position: absolute; margin-left: 200px; width: 200px"
				src="https://64.media.tumblr.com/44437aaa3af65ef5b5dc25b2cf1e81d1/6acc7d78ea83e207-45/s400x600/5b30035952803850ed3136fbe760ceff97ee8d19.gif"
				alt="Cute animated illustration"
			/>
		</div>
		<div class="buttons">
			<button class="btn" id="yesButton" @click="nextPage()">Yes</button>
			<button class="btn" id="noButton" @mouseover="moveButton()" @click="sorry()">No</button>
		</div>
	</div>
</template>

<script setup>
	import { ref, defineEmits } from 'vue';
	const emit = defineEmits(['next']);
	function nextPage() {
		emit('next');
	}

	function moveButton() {
		var x = Math.random() * (window.innerWidth - document.getElementById('noButton').offsetWidth);
		var y = Math.random() * (window.innerHeight - document.getElementById('noButton').offsetHeight);
		document.getElementById('noButton').style.left = `${x}px`;
		document.getElementById('noButton').style.top = `${y}px`;
	}

	function sorry() {
		let random = Math.floor(Math.random() * data.value.length);
		alert(data.value[random]);
	}

	const data = ref([
		'Answer not allowed',
		'Bat mo pinress NO? 😭',
		'Dami mong alam babe.',
		'Sorry wrong answer.',
		'Why you press this button?',
		'Bored kaba?',
	]);
</script>

<style scoped>
	.container {
		margin: auto;
		padding-top: 3em;
		align-items: center;
	}

	/* Position the "No" button absolutely within body */
	#noButton {
		position: absolute;
		margin-left: 150px;
		transition: 0.5s;
		border: 2px solid #ffffff;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Add a 2px horizontal, 2px vertical, 5px blur, and 0.3 opacity shadow */
	}

	#yesButton {
		position: absolute;
		margin-right: 150px;
		border: 2px solid #ffffff;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
	}

	.header_text {
		padding-bottom: 20px;
		font-family: 'Nunito';
		font-size: 50px;
		font-weight: bold;
		color: #e55451;
		-webkit-text-stroke: 0.5px white;
		text-align: center;
		margin-top: 20px;
		margin-bottom: 0px;
	}

	.buttons {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-top: 55px;
		margin-left: 20px;
		/* optional: adds some space between the buttons */
	}

	.btn {
		background-color: #80c3d8;
		color: white;
		padding: 15px 32px;
		text-align: center;
		display: inline-block;
		font-size: 16px;
		margin: 4px 2px;
		cursor: pointer;
		border: none;
		border-radius: 12px;
		transition: background-color 0.3s ease;
	}

	.btn:hover {
		background-color: #faf9f6;
	}

	.gif_container {
		display: flex;
		justify-content: center;
		align-items: flex-end; /* Align to the bottom */
		margin-left: 20px; /* Adjusted for consistency */
	}
</style>
