<template>
	<div id="body2" class="scissors">
		<div class="envelop">
			<div class="envelop__front-paper"></div>
			<div class="envelop__back-paper"></div>
			<div class="envelop__up-paper js-up-paper"></div>
			<div class="envelop__sticker js-sticker"></div>
			<div class="envelop__false-sticker"></div>
			<div class="envelop__content js-envelop-content">
				<div class="love-notes.value">
					<div class="note js-note">
						<div class="note__text">
							<p>
								Dear Airey, thank you for willingly accept to be my valentine. Theres a lot of
								presents waiting for you when you get back.
							</p>
						</div>
					</div>
					<div class="note js-note">
						<div class="note__text">
							<p>
								You have been working so hard bb, I hope you enjoy your last 2 weeks in Alice
								Springs and I can't wait for see you again!
							</p>
						</div>
					</div>
					<div class="note js-note">
						<div class="note__text">
							<p>You’re always in my heart.</p>
							<p>
								Te amo mensa &hearts;.<br />
								- George
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { onMounted, ref } from 'vue';
	import { gsap } from 'gsap';

	const mobile_media_query = ref(window.matchMedia('(max-width):400px)'));
	const tablet_media_query = ref(window.matchMedia('(min-width: 400px) and (max-width: 600px)'));
	const notes = ref(document.querySelectorAll('.js-note'));

	function recize_notes() {
		for (let i = 0; i < notes.value.length; i++) {
			if (notes.value[i].classList.contains('active')) {
				notes.value[i].classList.remove('active');
				gsap.set(notes.value[i], { height: '30%', clearProps: 'all' });
			}
		}
	}

	function notes_ready() {
		gsap.to('.js-envelop-content', { height: '110%', duration: 0.5 });
		for (let i = 0; i < notes.value.length; i++) {
			notes.value[i].addEventListener('click', function () {
				if (mobile_media_query.value.matches) {
					if (this.classList.contains('active')) {
						this.classList.remove('active');
						gsap.set(this, { height: '30%', clearProps: 'all' });
					} else {
						for (let i = 0; i < notes.value.length; i++) {
							if (notes.value[i].classList.contains('active')) {
								notes.value[i].classList.remove('active');
								gsap.set(notes.value[i], { height: '30%', clearProps: 'all' });
							}
						}
						this.classList.add('active');
						gsap.set(this, { height: 125 + 40 * i + '%' });
					}
				} else if (tablet_media_query.value.matches) {
					if (this.classList.contains('active')) {
						this.classList.remove('active');
						gsap.set(this, { height: '30%', clearProps: 'all' });
					} else {
						for (let i = 0; i < notes.value.length; i++) {
							if (notes.value[i].classList.contains('active')) {
								notes.value[i].classList.remove('active');
								gsap.set(notes.value[i], { height: '30%', clearProps: 'all' });
							}
						}
						this.classList.add('active');
						gsap.set(this, { height: 80 + 21 * i + '%' });
					}
				} else {
					if (this.classList.contains('active')) {
						this.classList.remove('active');
						gsap.set(this, { height: '30%', clearProps: 'all' });
					} else {
						for (let i = 0; i < notes.value.length; i++) {
							if (notes.value[i].classList.contains('active')) {
								notes.value[i].classList.remove('active');
								gsap.set(notes.value[i], { height: '30%', clearProps: 'all' });
							}
						}
						this.classList.add('active');
						gsap.set(this, { height: 70 + 20 * i + '%' });
					}
				}
			});
		}
	}

	function set_up_paper() {
		var arr = [0, 0, 100, 0, 50, 61];
		gsap.set('.js-up-paper', {
			bottom: '97%',
			rotation: 180,
			zIndex: 200,
			clipPath:
				'polygon(' +
				arr[0] +
				'%' +
				arr[1] +
				'%,' +
				arr[2] +
				'%' +
				arr[3] +
				'%,' +
				arr[4] +
				'%' +
				arr[5] +
				'%)',
			onComplete: notes_ready,
		});
	}

	function envelop_transition() {
		gsap.to('.js-up-paper', { bottom: '1%', duration: 0.25, onComplete: set_up_paper });
		document.querySelector('.js-up-paper').removeEventListener('click', envelop_transition);
		document.querySelector('.js-up-paper').classList.remove('cursor');
	}

	function sticker() {
		gsap.set('.js-sticker', { width: '20%', left: '-80%' });
		document.querySelector('#body2').classList.remove('scissors');

		document.querySelector('.js-sticker').removeEventListener('click', sticker);
		document.querySelector('.js-up-paper').addEventListener('click', envelop_transition);
		document.querySelector('.js-up-paper').classList.add('cursor');
	}

	onMounted(() => {
		document.querySelector('.js-sticker').addEventListener('click', sticker);
		window.onresize = () => {
			recize_notes.value();
		};
	});
</script>

<style scoped lang="scss">
	$bg: #000;

	$tab-bg: #f9f9f9;

	$f-color: #ba9872;
	$s-color: #967b5c;
	$t-color: #a38564;
	$fo-color: #f9f9f9;
	$fi-color: #e2e2e2;

	//Mixins
	@mixin posAbsolute($p) {
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		@if ($p == a) {
			position: absolute;
		} @else if($p == r) {
			position: relative;
		}
	}

	// General styles
	* {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
	}

	#body2 {
		height: 100%;
	}

	#body2 {
		width: 100%;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-height: 500px) {
			margin: 25% 0 25% 0;
		}
	}

	//Styles
	.envelop {
		width: 15rem;
		height: 10rem;
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;

		@media (min-width: 400px) and (max-width: 600px) {
			width: 20rem;
			height: 15rem;
		}
		@media (min-width: 600px) {
			width: 25rem;
			height: 20rem;
		}
		@media (min-width: 600px) and (min-height: 600px) {
			bottom: 20%;
		}

		&__front-paper {
			@include posAbsolute(a);
			border-radius: 0.7rem;
			border: 0.35rem solid $s-color;
			background-color: $f-color;
			clip-path: polygon(100% 0%, 50% 70%, 0% 0%, 0% 100%, 100% 100%);
			z-index: 300;
		}

		&__back-paper {
			@include posAbsolute(a);
			border-radius: 0.7rem;
			background-color: $s-color;
			clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 90%);
			z-index: 100;
		}

		&__up-paper {
			@include posAbsolute(r);
			border-radius: 0.7rem;
			background-color: $s-color;
			z-index: 400;
			clip-path: polygon(0% 0%, 100% 0%, 50% 81%);
		}

		&__sticker {
			width: 100%;
			height: 20%;
			position: absolute;
			margin: auto;
			top: 30%;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 400;
			background-image: url('https://www.freepnglogos.com/uploads/heart-png/emoji-heart-33.png');
			background-color: $fo-color;
			border: 0.3rem solid $fi-color;
			background-size: 2rem;
			background-position: center;

			@media (max-width: 400px) {
				background-size: 1.2rem;
			}
			@media (min-width: 600px) {
				height: 15%;
			}
		}

		&__false-sticker {
			width: 20%;
			height: 20%;
			position: absolute;
			margin: auto;
			top: 30%;
			left: 80%;
			bottom: 0;
			right: 0;
			z-index: 300;
			background-image: url('https://www.freepnglogos.com/uploads/heart-png/emoji-heart-33.png');
			background-color: $fo-color;
			border: 0.3rem solid $fi-color;
			background-size: 2rem;
			background-position: center;

			@media (max-width: 400px) {
				background-size: 1.2rem;
			}
			@media (min-width: 600px) {
				height: 15%;
			}
		}

		&__content {
			@include posAbsolute(a);
			z-index: 200;
		}
	}

	.love-notes.value {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.note {
		width: 95%;
		height: 30%;
		background-color: $tab-bg;
		position: absolute;
		overflow: hidden;
		transition: height 0.5s, opacity 0.25s;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);

		&:nth-child(1) {
			bottom: 60%;
		}

		&:nth-child(2) {
			bottom: 40%;
		}

		&:nth-child(3) {
			bottom: 20%;
		}

		&:hover {
			cursor: pointer;
			height: 60%;

			@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
				height: 90%;
			}
		}

		&__text {
			font-family: 'Sofia';
			padding: 1rem;

			p {
				font-size: 0.9rem;
				margin-bottom: 1rem;
				text-align: center;

				@media (min-width: 300px) and (max-width: 600px) {
					font-size: 1.025rem;
				}
				@media (min-width: 600px) {
					font-size: 1.15rem;
				}
			}
		}
	}

	.scissors {
		cursor: url('https://i.postimg.cc/GtLCdKxR/sisors.png'), pointer;
		&:active {
			cursor: url('https://i.postimg.cc/GtXQ7WPZ/pngwing-com.png'), pointer;
		}
	}

	.cursor {
		cursor: pointer;
	}
</style>
