<template>
	<div class="body">
		<ValentineQuestion v-if="!sheSaidYes" v-on:next="showLoveLetter()" />
		<LoveLetter v-else />
		<div class="footer">With love by yours truly, George Somoso ♥️</div>
	</div>
</template>

<script>
	import ValentineQuestion from './components/ValentineQuestion';
	import LoveLetter from './components/LoveLetter';
	export default {
		name: 'App',
		components: {
			ValentineQuestion,
			LoveLetter,
		},
		data() {
			return {
				sheSaidYes: false,
			};
		},
		methods: {
			showLoveLetter() {
				this.sheSaidYes = true;
			},
		},
	};
</script>

<style>
	html {
		background-color: #cbe6ef;
		margin: 0 !important;
		width: 100vw;
		height: 100vh;
	}
	.body {
		display: block;
		height: 100vh;
	}
	.footer {
		position: absolute;
		width: 100vw;
		bottom: 0;
		text-align: center;
		height: 2em;
	}
</style>
